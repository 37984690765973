<template>
  <Section class="section--border-bottom bg-gray-lightestest">
    <Container>
      <Timeline
        :index="step"
        :steps-data="[
          'Modalités',
          'Entités',
          'Récapitulatif',
        ]"
      />
    </Container>
  </Section>
  <Section class="no-padding-bottom">
    <Container>
      <Btn
        :text="btnText"
        color="default"
        @click="toggleAll"
      />
    </Container>
  </Section>
  <Section>
    <Container>
      <ModalitiesTable
        :checkbox="true"
        checkboxKey="id"
        :protocol_id="$route.params.id"
        :defaultSelectedItems="Object.keys($store.state.protocol.modalities).length > 0
        ? $store.state.protocol.modalities
        : null"
        @selected-items="selectItems"
        @modalities="setModalities($event)"
        />
    </Container>
  </Section>

</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Timeline from '@/components/layout/Timeline.vue'
import ModalitiesTable from '@/views/componentsViews/modalities/ModalitiesTable.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ModalitiesAddSubview',

  components: {
    ModalitiesTable,
    Section,
    Container,
    Timeline,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      protocol: {
        bpe: false,
      },
      step: 0,
      routeName: this.$route.name,
      previousRouteName: null,
      errors: {},

      // Table
      headers: [],
      staticHeaders: [
        'Désignation',
        'Numéro local',
        'Destruction de la modalité',
        'Type',
        'Partage de la modalité',
      ],

      // Protocol
      protocolId: this.$route.params.id,

      // Modalites
      modalities: [],

      // Factors
      factors: [],
      factorIndex: 0,

      // Passages
      passagesFactors: [],

      // Form
      stades: {},

      selectedItems: [],

      btnText: 'Tout sélectionner',
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  computed: {
    isBpe: {
      get() {
        return (this.protocol.bpe) ?? false
      },
      set(v) {
        this.protocol = v
        this.componentKey += 1
      },
    },
  },

  mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      nextRouteName: 'protocolEntities',
      routeParams,
    })

    this.emitter.on('selected-items', this.selectItems)
    this.emitter.emit('close-loader')
  },

  methods: {
    toggleAll() {
      if (this.selectedItems.length < this.modalities.length) {
        this.selectedItems = this.modalities.map((m) => m.id)
        this.btnText = 'Tout désélectionner'
      } else {
        this.selectedItems = []
        this.btnText = 'Tout sélectionner'
      }

      this.$store.dispatch('protocol/selectModalities', this.selectedItems)
      // eslint-disable-next-line
      //location.reload()
    },

    setModalities(event) {
      this.modalities = event

      if (Object.keys(this.$store.state.protocol.modalities).length === this.modalities.length) {
        this.btnText = 'Tout désélectionner'
      }
    },

    selectItems(event) {
      this.selectedItems = event.selected
      if (this.selectedItems.length === this.modalities.length) {
        this.btnText = 'Tout désélectionner'
      } else {
        this.btnText = 'Tout sélectionner'
      }

      this.$store.dispatch('protocol/selectModalities', this.selectedItems)
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep(.checkbox) {
    justify-content: center;
  }
</style>
